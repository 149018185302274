<template>
  <div>
    <div v-if="haveData">
      <div v-if="isAdmin">
        <admin-detail
          :id="id"
          :programme="programme"
          @reload-programme="load()"
        />
      </div>
      <div v-else>
        <client-detail
          :id="id"
          :programme="programme"
        />
      </div>
    </div>
  </div>
</template>
<script>
import CryptoJS from 'crypto-js'
import adminDetail from '@/views/programmes/AdminDetail.vue'
import clientDetail from '@/views/programmes/ClientDetail.vue'

const config = require('@/../config')

export default {
  components: {
    adminDetail,
    clientDetail,
  },
  data() {
    return {
      id: null,
      programme: {},
      user: null,
      isAdmin: false,
      haveData: false,
    }
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem('userData'))
    this.isAdmin = this.user.role.includes('ROLE_ADMIN')
    this.load()
  },
  methods: {
    load() {
      const encryptedId = this.$route.params.id
      const bytes = CryptoJS.AES.decrypt(encryptedId, config.crypto_key)
      this.id = parseInt(bytes.toString(CryptoJS.enc.Utf8), 10)

      this.$store.dispatch('projets/fetchOne', this.id).then(data => {
        this.programme = data
        this.haveData = true
      })
    },
  },
}
</script>

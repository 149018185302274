<template>
  <div>
    <!-- search input -->
    <section id="knowledge-base-search">
      <b-card
        no-body
        class="knowledge-base-bg text-center"
        :style="{backgroundImage: `url(${require('@/assets/images/banner/banner.png')})`}"
      >
        <b-card-body class="card-body">
          <b-row>
            <b-col md="3" />
            <b-col md="3">
              <b-form-group>
                <label for="nom">Nom :</label>
                <b-form-input
                  id="nom"
                  v-model="programme.nom"
                  type="text"
                  placeholder="Nom du programme"
                />
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group>
                <label for="nom">Etat :</label>
                <b-form-input
                  id="nom"
                  v-model="programme.etat"
                  type="text"
                  placeholder="Etat du programme"
                />
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-button
                class="mt-2"
                @click="update"
              >
                Mettre à jour
              </b-button>
            </b-col>
          </b-row>

        </b-card-body>
      </b-card>
    </section>
    <!--/ search input -->

    <section id="knowledge-base-content">
      <b-row>

        <b-col cols="6">
          <b-card>
            <b-card-title>
              Documents
            </b-card-title>
            <b-card-body>
              <b-row>
                <b-col md="6">
                  <b-button v-b-modal.modal-addFile>
                    Ajouter un document
                  </b-button>
                </b-col>
              </b-row>
              <b-row class="kb-search-content-info match-height mt-2">
                <b-col cols="12">
                  <b-table
                    striped
                    hover
                    responsive
                    :items="programme.documents"
                    :fields="fields"
                  >
                    <template #cell(document)="data">
                      <span class="mb-1"> {{ data.item.nom }} </span>
                    </template>

                    <template #cell(actions)="data">
                      <b-button
                        v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                        variant="flat-primary"
                        class="btn-icon"
                        @click="download(data.item.id)"
                      >
                        <feather-icon icon="DownloadIcon" />
                      </b-button>

                      <b-button
                        v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                        variant="flat-danger"
                        class="btn-icon"
                        @click="remove(data.item)"
                      >
                        <feather-icon icon="Trash2Icon" />
                      </b-button>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>

        <b-col cols="6">
          <b-card>
            <b-card-title>
              Liste de diffusion pour ce lotissement
            </b-card-title>
            <b-card-body>
              <b-row>
                <b-col cols="6">
                  <b-button v-b-modal.modal-addContact>
                    Ajouter un contact
                  </b-button>
                </b-col>
              </b-row>
              <b-row class="kb-search-content-info match-height mt-2">
                <b-col cols="12">
                  <b-table
                    striped
                    hover
                    responsive
                    :items="contacts"
                    :fields="fieldsContact"
                  >
                    <template #cell(email)="data">
                      <span class="mb-1"> {{ data.item.email }} </span>
                    </template>

                    <template #cell(actions)="data">
                      <b-button
                        v-if="data.item.id !== null"
                        v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                        variant="flat-danger"
                        class="btn-icon"
                        @click="removeContact(data.item)"
                      >
                        <feather-icon icon="Trash2Icon" />
                      </b-button>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <!-- content -->
    </section>
    <!-- Modal Upload -->
    <b-modal
      id="modal-addFile"
      ref="modal-addFile"
      cancel-variant="outline-secondary"
      ok-title="Ajouter"
      cancel-title="Annuler"
      centered
      title="Ajouter un document"
      @show="resetModal('modal-addFile')"
      @hidden="resetModal('modal-addFile')"
      @ok="handleOk"
    >
      <b-form
        ref="newFileForm"
        @submit.stop.prevent="handleSubmit"
      >
        <b-alert
          :show="newDoc.error === true"
          variant="danger"
        >
          <h4 class="alert-heading">
            Merci de remplir tout les champs
          </h4>
        </b-alert>
        <b-form-group>
          <label for="nom">Document :</label>
          <b-form-input
            id="nom"
            v-model="newDoc.nom"
            type="text"
            placeholder="Nom du document"
          />
        </b-form-group>

        <b-form-group>
          <label for="nom">Fichier :</label>
          <b-form-file
            v-model="newDoc.file"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
          />
        </b-form-group>
      </b-form>
    </b-modal>
    <!-- Modal Contact -->
    <b-modal
      id="modal-addContact"
      ref="modal-addContact"
      cancel-variant="outline-secondary"
      ok-title="Ajouter"
      cancel-title="Annuler"
      centered
      title="Ajouter un contact"
      @show="resetModal('modal-addContact')"
      @hidden="resetModal('modal-addContact')"
      @ok="handleOkContact"
    >
      <b-form
        ref="newContactForm"
        @submit.stop.prevent="addContact"
      >
        <b-alert
          :show="newContact.error === true"
          variant="danger"
        >
          <h4 class="alert-heading">
            Merci de remplir tout les champs
          </h4>
        </b-alert>
        <b-form-group>
          <label for="nom">Email :</label>
          <b-form-input
            id="email"
            v-model="newContact.email"
            type="text"
            placeholder="Email"
          />
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>
<script>
import {
  BRow, BCol, BCard, BCardBody, BButton, BModal, VBModal, BForm, BFormInput, BFormGroup, BFormFile, BAlert, BTable, BCardTitle,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BFormFile,
    BAlert,
    BTable,
    BCardTitle,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    id: Number,
    programme: Object,
  },
  data() {
    return {
      newDoc: {
        nom: '',
        file: null,
        error: false,
      },
      newContact: {
        email: '',
        error: false,
      },
      fields: [
        { key: 'document', label: 'Document' },
        { key: 'actions', label: '' },
      ],
      fieldsContact: [
        { key: 'email', label: 'Email' },
        { key: 'actions', label: '' },
      ],
      contacts: [],
      removedContact: null,
      removedDocument: null,
    }
  },
  mounted() {
    this.contacts = []
    this.programme.diffusionListes.map(item => {
      this.contacts.push({
        id: item.id,
        email: item.associatedEmail,
      })
      return true
    })

    this.programme.users.map(item => {
      this.contacts.push({
        id: null,
        email: item.email,
      })
      if (item.emailSecondaire) {
        this.contacts.push({
          id: null,
          email: item.emailSecondaire,
        })
      }
    })
  },
  methods: {
    download(id) {
      this.$store.dispatch('documents/getPj', id).then(data => {
        const a = document.createElement('a') // Create <a>
        a.href = `data:${data.contentType};base64,${data.base64}` // Image Base64 Goes here
        a.download = data.name // File name Here
        a.click()
      })
    },
    remove(item) {
      this.$store.dispatch('documents/remove', item.id)
        .then(() => {
          this.$emit('reload-programme')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Document supprimé du programme',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
        })
    },
    resetModal(ref) {
      this.newDoc.nom = ''
      this.newDoc.file = null
      this.newDoc.error = false

      this.newContact.email = ''
      this.newContact.error = false
      this.$refs[ref].hide()
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()

      if (this.newDoc.nom !== '' && this.newDoc.file !== null) {
        // Trigger submit handler
        this.handleSubmit()
      } else {
        this.newDoc.error = true
      }
    },
    handleSubmit() {
      const that = this
      const form = new FormData()
      form.append('nom', that.newDoc.nom)
      form.append('filename', that.newDoc.file.name)
      form.append('extension', that.newDoc.file.type)
      form.append('projet', that.programme.id)
      form.append('file', that.newDoc.file)

      that.$store.dispatch('documents/insert', form)
        .then(res => {
          this.programme.documents.push({
            '@id': `/api/documents/${res.data}`,
            ' @type': 'Document',
            etat: 'actif',
            extension: that.newDoc.file.type,
            id: res.data,
            nom: that.newDoc.nom,
          })
          this.resetModal('modal-addFile')
        })
    },
    handleOkContact(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()

      if (this.newContact.email !== '') {
        // Trigger submit handler
        this.addContact()
      } else {
        this.newContact.error = true
      }
    },
    addContact() {
      const that = this
      if (that.contacts.filter(item => item.email === that.newContact.email).length > 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Email déja présent dans la liste de diffusion',
            icon: 'BellIcon',
            variant: 'danger',
          },
        })
      } else {
        that.$store.dispatch('diffusionsListe/insert', { associatedEmail: that.newContact.email, associatedProjet: that.programme['@id'] })
          .then(res => {
            this.contacts.push({
              id: res.data.id,
              email: res.data.associatedEmail,
            })
            this.resetModal('modal-addContact')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Email ajouté',
                icon: 'BellIcon',
                variant: 'success',
              },
            })
          })
      }
    },
    removeContact(item) {
      this.removedContact = item.id
      this.$store.dispatch('diffusionsListe/remove', item.id)
        .then(() => {
          this.contacts = this.contacts.filter(el => el.id !== this.removedContact)
          this.removedContact = null
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Email supprimé de la liste de diffusion',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
        })
    },
    update() {
      this.$store.dispatch('projets/update', this.programme).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Modification effectuée',
            icon: 'BellIcon',
            variant: 'success',
          },
        })
      })
    },
  },
}

</script>
<style lang="scss">
@import '@core/scss/vue/pages/page-knowledge-base.scss';
</style>

<template>
  <div>
    <!-- search input -->
    <section id="knowledge-base-search">
      <b-card
        no-body
        class="knowledge-base-bg text-center"
        :style="{backgroundImage: `url(${require('@/assets/images/banner/banner.png')})`}"
      >
        <b-card-body class="card-body">
          <h2 class="text-primary">
            {{ programme.nom }}
          </h2>
        </b-card-body>
      </b-card>
    </section>
    <!--/ search input -->

    <section id="knowledge-base-content">
      <!-- content -->
      <b-row class="kb-search-content-info match-height mt-2">
        <b-col
          v-for="item in programme.documents"
          :key="item.id"
          md="3"
          sm="4"
          class="kb-search-content"
        >
          <b-card
            class="text-center cursor-pointer"
            @click="download(item.id)"
          >
            <b-avatar
              class="mb-1"
              variant="light-primary"
              size="60"
            >

              <feather-icon
                size="30"
                :icon="getIcon(item.extension)"
              />
            </b-avatar>
            <h4 class="mt-1">
              {{ item.nom }}
            </h4>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </section>

  </div>
</template>
<script>
import {
  BRow, BCol, BCard, BCardBody, BButton, BAlert, BAvatar,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BAvatar,
  },
  directives: {
    Ripple,
  },
  props: {
    id: Number,
    programme: Object,
  },
  methods: {
    download(id) {
      this.$store.dispatch('documents/getPj', id).then(data => {
        const a = document.createElement('a') // Create <a>
        a.href = `data:${data.contentType};base64,${data.base64}` // Image Base64 Goes here
        a.download = data.name // File name Here
        a.click()
      })
    },
    getIcon(ext) {
      switch (ext) {
        case 'application/pdf':
          return 'FileTextIcon'
          break

        default:
          return 'ImageIcon'
          break
      }
    },
  },
}

</script>
<style lang="scss">
@import '@core/scss/vue/pages/page-knowledge-base.scss';
</style>
